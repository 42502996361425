import { fetchEndpoint,fetchGetPoint} from "@/server/network1";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//获取访客统计 传学校id获取某个学校统计信息
export const visitorDetail = (data) => fetchGetPoint(
    "/school/visitor/detail/statistics", { ...data }, true, 'GET'
)

//获取某个学校访客列表
export const detailList = (data) => fetchGetPoint(
    "/school/visitor/detail/list", { ...data }, true, 'GET'
)
//获取某个人的访客记录
export const detailRecord = (data) => fetchGetPoint(
    "/school/visitor/detail/person", { ...data }, true, 'GET'
)
//获取访客机状态
export const statusList = (data) => fetchEndpoint(
    "/school/visitor/device/status", { ...data }, true, 'GET'
)
