<template>
  <a-modal
    :title="title"
    :width="1200"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="confirmLoading" >
     <p class="header"><span>访客姓名:{{queryParam.name}}</span><span>访客证件号:{{getIdCard(queryParam.idCard) }}</span><span>联系方式:{{phoneNumber}}</span></p>

       <a-table
        bordered
        :columns="columns"
        :dataSource="dataList"
        :pagination="pagination"
        :rowKey="(record) => record.id"
         @change="handleTableChange"
      >
        <!-- 点击姓名-->   
        <span slot="code" slot-scope="text, record">
         <a v-if="text==1" style="color:#50D84B">绿码</a>
         <a v-if="text==2">黄码</a>
         <a v-if="text==3">红码</a>
         <a v-if="text==4">未知</a>
        </span>
      </a-table>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from "moment";
import {
  detailRecord
  
} from '@/api/visitor'
export default {
  data() {
    return {
      title: "查看",
      visible: false,
      confirmLoading: false,
     phoneNumber:"",
      size: "default",
      loading: false,
        queryParam:{
         page:1,
        pageSize:10
      },
      dataList:[],
      pagination: {
        current:1,
        total: 0,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "40", "80"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
          columns: [
        {
          title: "出入学校",
          align: "center",
          dataIndex: "school",
          width: 60,

        },
        {
          title: "被访人",
          align: "center",
          dataIndex: "interviewee",
          width: 60,
        },

        {
          title: "健康码",
          align: "center",
          dataIndex: "healthCode",
          width: 60,
          scopedSlots: { customRender: "code" },

        },
        {
          title: "来访时间",
          align: "center",
          dataIndex: "comeTime",
          width: 60,
        },
        {
          title: "签离时间",
          align: "center",
          dataIndex: "backTime",
          width: 60,
        },
      ],
      form: this.$form.createForm(this),
    };
  },
  methods: {
    moment,
    async getVisitor(parameter) {
      detailRecord(Object.assign(parameter, this.queryParam)).then(res=>{
         this.dataList=res.result.list
         this.pagination.total= res.result.total
      })
      // await this.$store.dispatch("detailRecord",Object.assign(parameter, this.queryParam)).then((res) => {
      //     this.dataList=res.result.list
      //     this.pagination.total= res.result.total
      // });
    },
    //弹框取消
    handleCancel() {
      this.visible = false;
    },
    //身份证号脱敏
    getIdCard(t){
var str1 = t.slice(0, 6)
              var str2 = ''
              var str3 = t.slice(16)

              if (t && t.length == 15) {
                str2 = '********'
              } else {
                str2 = '**********'
              }
              t = str1 + str2 + str3
            
            return t
    },
    //显示弹框，保存数据
    async showModal(record) {
    this.visible=true
    this.title=`${record.name}的出入轨迹`
    //获取人员轨迹详情
    this.queryParam.idCard=record.idCard
    this.queryParam.name=record.name
    this.phoneNumber=record.phone
    this.getVisitor({});

    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page=pagination.current
      this.queryParam.pageSize=pagination.pageSize
      this.getVisitor({})
    },
    //关闭弹窗

    close() {
      this.visible = false;
      this.$emit("addBack", {});
    },
  },
};
</script>

<style lang="less" scoped>
.header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansSC-regular;
    
    span{
        width: 33%;
        font-weight: 600;
    }
}
</style>