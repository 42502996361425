<template>
  <div :bordered="false" class="border">
    <!-- <a-button type="primary" class="back" @click="() => $router.back()"
      >返回</a-button
    > -->
    <div class="up">
      <div class="title">{{ $route.query.name }}</div>
      <div
        style="width: 100%; height: 1px; background: #f9f9f9; margin: 16px 0"
      ></div>
      <div class="content">
        <div
          style="
            font-size: 14px;
            border-left: 3px solid #4292f7;
            padding-left: 5px;
          "
        >
          数据总览
        </div>
        <div class="count">
          <div class="total">
            <p>累计访客(人）</p>
            <p>{{ countData.total }}</p>
          </div>
          <a-divider type="vertical" style="height: 38px" />
          <div class="total">
            <span class="dot" style="background: #69bbf1"></span>
            <p v-if="queryParam.cycleType == 1">今日访客</p>
            <p v-if="queryParam.cycleType == 2">本周访客</p>
            <p v-if="queryParam.cycleType == 3">本月访客</p>
            <p v-if="queryParam.cycleType == 4">本年访客</p>
            <p>{{ countData.cycleTotal || 0 }}</p>
          </div>
          <a-divider type="vertical" style="height: 38px" />

          <div class="total">
            <p>日均访客(人）</p>
            <p>{{ countData.average }}</p>
          </div>
        </div>
        <div class="date">
          <span
            :style="{ color: queryParam.cycleType == 1 ? '#4292F7' : '#000' }"
            @click="tabChange(1)"
            >日</span
          >
          <span
            :style="{ color: queryParam.cycleType == 2 ? '#4292F7' : '#000' }"
            @click="tabChange(2)"
            >周</span
          >
          <span
            :style="{ color: queryParam.cycleType == 3 ? '#4292F7' : '#000' }"
            @click="tabChange(3)"
            >月</span
          >
          <span
            :style="{ color: queryParam.cycleType == 4 ? '#4292F7' : '#000' }"
            @click="tabChange(4)"
            >年</span
          >
        </div>
        <div class="tab">
          <div class="visit">
            <div>
              <span class="dot" style="background: #69bbf1"></span>

              <p>访客机状态</p>
              <p>{{ countData.deviceStatus }}</p>
            </div>
            <div>
              <span class="dot" style="background: #50d84b"></span>
              <p v-if="queryParam.cycleType == 1">今日绿码</p>
              <p v-if="queryParam.cycleType == 2">本周绿码</p>
              <p v-if="queryParam.cycleType == 3">本月绿码</p>
              <p v-if="queryParam.cycleType == 4">本年绿码</p>
              <p>{{ countData.greenCodeNum || 0 }}</p>
            </div>
            <div>
              <span class="dot" style="background: #f9eb61"></span>
              <p v-if="queryParam.cycleType == 1">今日黄码</p>
              <p v-if="queryParam.cycleType == 2">本周黄码</p>
              <p v-if="queryParam.cycleType == 3">本月黄码</p>
              <p v-if="queryParam.cycleType == 4">本年黄码</p>
              <p>{{ countData.yellowCodeNum || 0 }}</p>
            </div>
            <div>
              <span class="dot" style="background: #f33a3a"></span>
              <p v-if="queryParam.cycleType == 1">今日红码</p>
              <p v-if="queryParam.cycleType == 2">本周红码</p>
              <p v-if="queryParam.cycleType == 3">本月红码</p>
              <p v-if="queryParam.cycleType == 4">本年红码</p>
              <p>{{ countData.redCodeNum || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="down">
      <div
        style="
          font-size: 14px;
          border-left: 3px solid #4292f7;
          padding-left: 5px;
          margin: 5px 0;
        "
      >
        数据详情
      </div>
      <span style="margin: 8px 16px">日期:</span>
      <a-range-picker
        type="primary"
        style="width: 20%"
        :disabled-date="disabledDate"
        :value="[queryParam.startTime, queryParam.endTime]"
        @change="onSelect"
      ></a-range-picker>
      <span style="margin: 8px 16px">访客姓名:</span>
      <a-input
        type="primary"
        style="width: 20%"
        v-model="queryParam.visitorName"
        placeholder="请输入访客姓名"
      ></a-input>
      <a-button type="primary" @click="handleSearch" style="margin: 8px 32px"
        >查询</a-button
      >
      <a-button @click="handleReset" style="margin: 8px 8px">重置</a-button>

      <a-table
        bordered
        :columns="columns"
        :dataSource="dataList"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        @change="handleTableChange"
      >
        <!-- 点击姓名-->
        <span slot="name" slot-scope="text, record">
          <a @click="$refs.openModal.showModal(record)">{{ text }}</a>
        </span>

        <span slot="code" slot-scope="text, record">
          <a v-if="text == 1" style="color: #50d84b">绿码</a>
          <a v-if="text == 2">黄码</a>
          <a v-if="text == 3">红码</a>
          <a v-if="text == 4">未知</a>
        </span>
      </a-table>
    </div>
    <visitor-modal ref="openModal" @addBack="loadList"></visitor-modal>
  </div>
</template>

<script>
import VisitorModal from "./VisitorModal.vue";
import {visitorDetail,detailList} from '@/api/visitor'
import moment from "moment";

export default {
  name: "VisitorRecord",
  mixins: [],
  components: { VisitorModal },

  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      queryParam: {
        page: 1,
        pageSize: 10,
        cycleType: 1,
        startTime: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
        endTime: moment(new Date()).format("YYYY-MM-DD"),
      },
      dataList: [],
      countData: {},
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "40", "80"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      columns: [
        {
          title: "访客姓名",
          align: "center",
          dataIndex: "name",
          width: 60,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "被访人",
          align: "center",
          dataIndex: "interviewee",
          width: 60,
        },
        {
          title: "访客证件号",
          align: "center",
          dataIndex: "idCard",
          width: 60,
          customRender: function (t, r, index) {
            if (t) {
              var str1 = t.slice(0, 6);
              var str2 = "";
              var str3 = t.slice(16);

              if (t && t.length == 15) {
                str2 = "********";
              } else {
                str2 = "**********";
              }
              t = str1 + str2 + str3;
            }
            return t;
          },
        },
        {
          title: "联系方式",
          align: "center",
          dataIndex: "average",
          width: 60,
        },
        {
          title: "健康码",
          align: "center",
          dataIndex: "healthCode",
          width: 60,
          scopedSlots: { customRender: "code" },
        },
        {
          title: "来访时间",
          align: "center",
          dataIndex: "comeTime",
          width: 60,
        },
        {
          title: "签离时间",
          align: "center",
          dataIndex: "backTime",
          width: 60,
        },
      ],
    };
  },
  created() {
    this.loadList({});
    this.loadCount({});
  },
  computed: {},
  methods: {
    //禁止选择当前时间以后的时间
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    //点击重置
    handleReset() {
      this.queryParam.startTime = moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");

      this.queryParam.endTime = moment(new Date()).format("YYYY-MM-DD");
      this.queryParam.visitorName = "";
      this.loadList({})
       this.loadCount({});
    },
    //tab切换
    tabChange(num) {
      this.queryParam.cycleType = num;
      this.pagination.current = 1;
      this.queryParam.page = 1;
      this.loadList({});
      this.loadCount({});
    },
    onSelect: async function (value, dateString) {
      this.queryParam.startTime = dateString[0];
      this.queryParam.endTime = dateString[1];
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.queryParam.pageSize = pagination.pageSize;
      this.loadList({});
    },
    loadCount: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "visitorDetail",
      //   Object.assign(parameter, this.queryParam)
      // );
      // if (res.code == 200) {
      //   this.countData = res.result;
      // }
      let data={
        cycleType:this.queryParam.cycleType
      }
      visitorDetail(data).then((result) => {
         this.countData = result.result
      }).catch((err) => {
        
      });
    },
    loadList: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "detailList",
      //   Object.assign(parameter, this.queryParam)
      // );
      // if (res.code == 200) {
      //   this.dataList = res.result.list;
      //   this.pagination.total = res.result.total;
      // }
      detailList(Object.assign(parameter, this.queryParam)).then(res=>{
         if (res.code == 200) {
        this.dataList = res.result.list;
        this.pagination.total = res.result.total;
      }
      })
    },

    handleSearch() {
      this.queryParam.page = 1;
      this.pagination.current = 1;
      this.loadList({});
    },
  },
};
</script>
<style lang="less" scoped>
.border {
  width: 100%;
  position: relative;
  .back {
    position: absolute;
    top: 13px;
    right: 32px;
  }
  .up {
    width: 100%;
    height: 450px;
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 12px;
    padding: 12px 16px;

    .content {
      position: relative;
      .count {
        display: flex;
        justify-content: center;
        align-items: center;

        .total {
          width: 200px;
          height: 200px;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          padding: 50px 0 0 0;
        }
      }
      .date {
        width: 193px;
        height: 32px;
        background: #ffffff;
        display: flex;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        right: 11%;
        top: 45%;
        line-height: 32px;
        span {
          width: 50px;
          border: 1px solid #d9d9d9;
        }
      }
      .tab {
        position: relative;

        .visit {
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            text-align: center;
            width: 320px;
            height: 150px;
            background: #ffffff;
            border-radius: 3px;
            box-shadow: 0px 0px 5px #ccc;
            margin: 0 16px;
            position: relative;
            p {
              margin-top: 20px;
              font-size: 28px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #000000;
            }
            .dot {
              position: absolute;
              left: 16px;
              top: 18px;
              width: 20px;
              height: 20px;
              display: block;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .down {
    width: 100%;
    min-height: 400px;
    background: #ffffff;
    border-radius: 2px;
    padding: 12px 16px;
  }
}
</style>